import React from 'react';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const AdminHubStatementBillingCenter = loadable(() => import("@/pages/AdminHub/StatementBillingCenter")); // prettier-ignore
const AdminHubStatementBillingCenterBills = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Bills")); // prettier-ignore
const AdminHubStatementBillingCenterArAgingReport = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/ArAgingReport")); // prettier-ignore
const AdminHubStatementBillingCenterBillsByMonth = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Bills/BillsByMonth")); // prettier-ignore
const AdminHubStatementBillingCenterBillsByResident = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Bills/BillsByResident")); // prettier-ignore
const AdminHubStatementBillingCenterStatements = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Statements")); // prettier-ignore
const AdminHubStatementBillingCenterStatementsByMonth = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Statements/StatementsByMonth")); // prettier-ignore
const AdminHubStatementBillingCenterStatementsByResident = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Statements/StatementsByResident")); // prettier-ignore
const AdminHubStatementBillingCenterDeposits = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Deposits")); // prettier-ignore
const AdminHubStatementBillingCenterDepositsByMonth = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Deposits/DepositsByMonth")); // prettier-ignore
const AdminHubStatementBillingCenterDepositsByResident = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Deposits/DepositsByResident")); // prettier-ignore
const AdminHubStatementBillingCenterResidentBill = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/ResidentBill")); // prettier-ignore
const AdminHubStatementBillingCenterResidentOneTimeBill = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/ResidentOneTimeBill")); // prettier-ignore
const AdminHubStatementBillingCenterSettings = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Settings")); // prettier-ignore
const AdminHubStatementBillingCenterSettingsGeneral = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Settings/General")); // prettier-ignore
const AdminHubStatementBillingCenterCustomizeGeneralSettings = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/CustomizeGeneralSettings")); // prettier-ignore
const AdminHubStatementBillingCenterSettingsResidentDeliveryPods = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Settings/ResidentDeliveryPods")); // prettier-ignore
const AdminHubStatementBillingCenterManagePaymentMethods = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/ManagePaymentMethods")); // prettier-ignore
const AdminHubStatementBillingCenterPayments = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Payments")); // prettier-ignore
const AdminHubStatementBillingCenterPaymentsTransactions = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/Payments/Transactions")); // prettier-ignore
const AdminHubStatementBillingCenterOneTimeBills = loadable(() => import("@/pages/AdminHub/StatementBillingCenter/OneTimeBills")); // prettier-ignore

import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

export enum AdminHubStatementBillingCenterRoutes {
  AdminHubStatementBillingCenter = '/:domain/admin-hub/statement-billing-center',
  AdminHubStatementBillingCenterBills = '/:domain/admin-hub/statement-billing-center/bills',
  AdminHubStatementBillingCenterArAgingReport = '/:domain/admin-hub/statement-billing-center/ar-aging',
  AdminHubStatementBillingCenterBillsByMonth = '/:domain/admin-hub/statement-billing-center/bills/by-month',
  AdminHubStatementBillingCenterBillsByResident = '/:domain/admin-hub/statement-billing-center/bills/by-resident',
  AdminHubStatementBillingCenterResidentBill = '/:domain/admin-hub/statement-billing-center/resident/:resident_id/bill/:bill_id',
  AdminHubStatementBillingCenterResidentOneTimeBill = '/:domain/admin-hub/statement-billing-center/resident/:resident_id/one-time-bill/:bill_id',
  AdminHubStatementBillingCenterPayments = '/:domain/admin-hub/statement-billing-center/payments',
  AdminHubStatementBillingCenterPaymentsTransactions = '/:domain/admin-hub/statement-billing-center/payments/transactions',
  AdminHubStatementBillingCenterSettings = '/:domain/admin-hub/statement-billing-center/settings',
  AdminHubStatementBillingCenterSettingsGeneral = '/:domain/admin-hub/statement-billing-center/settings/general',
  AdminHubStatementBillingCenterCustomizeGeneralSettings = '/:domain/admin-hub/statement-billing-center/customize-general-settings',
  AdminHubStatementBillingCenterSettingsResidentDeliveryPods = '/:domain/admin-hub/statement-billing-center/settings/resident-delivery-pods',
  AdminHubStatementBillingCenterManagePaymentMethods = '/:domain/admin-hub/statement-billing-center/manage-payment-methods/resident/:resident_id',
  AdminHubStatementBillingCenterStatements = '/:domain/admin-hub/statement-billing-center/statements',
  AdminHubStatementBillingCenterStatementsByMonth = '/:domain/admin-hub/statement-billing-center/statements/by-month',
  AdminHubStatementBillingCenterStatementsByResident = '/:domain/admin-hub/statement-billing-center/statements/by-resident',
  AdminHubStatementBillingCenterDeposits = '/:domain/admin-hub/statement-billing-center/deposits',
  AdminHubStatementBillingCenterDepositsByMonth = '/:domain/admin-hub/statement-billing-center/deposits/by-month',
  AdminHubStatementBillingCenterDepositsByResident = '/:domain/admin-hub/statement-billing-center/deposits/by-resident',
  AdminHubStatementBillingCenterOneTimeBills = '/:domain/admin-hub/statement-billing-center/one-time-bills'
}

export const getAdminHubStatementBillingCenterRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    ...(currentUser.isBillingEnabledForUserAndOrg() &&
    FeatureFlagService.isEnabled(ExacareFeature.BILLING_V2)
      ? [
          {
            path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenter,
            element: <AdminHubStatementBillingCenter />,
            children: [
              ...(FeatureFlagService.isEnabled(
                ExacareFeature.BILLING_AR_AGING_REPORT
              )
                ? [
                    {
                      path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterArAgingReport,
                      element: <AdminHubStatementBillingCenterArAgingReport />
                    }
                  ]
                : []),
              {
                path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterBills,
                element: <AdminHubStatementBillingCenterBills />,
                children: [
                  {
                    path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterBillsByMonth,
                    element: <AdminHubStatementBillingCenterBillsByMonth />
                  },
                  {
                    path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterBillsByResident,
                    element: <AdminHubStatementBillingCenterBillsByResident />
                  }
                ]
              },
              {
                path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterPayments,
                element: <AdminHubStatementBillingCenterPayments />,
                children: [
                  {
                    path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterPaymentsTransactions,
                    element: (
                      <AdminHubStatementBillingCenterPaymentsTransactions />
                    )
                  }
                ]
              },
              ...(FeatureFlagService.isEnabled(
                ExacareFeature.BILLING_STATEMENTS
              )
                ? [
                    {
                      path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterStatements,
                      element: <AdminHubStatementBillingCenterStatements />,
                      children: [
                        {
                          path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterStatementsByMonth,
                          element: (
                            <AdminHubStatementBillingCenterStatementsByMonth />
                          )
                        },
                        {
                          path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterStatementsByResident,
                          element: (
                            <AdminHubStatementBillingCenterStatementsByResident />
                          )
                        }
                      ]
                    }
                  ]
                : []),
              ...(FeatureFlagService.isEnabled(
                ExacareFeature.BILLING_DEPOSITS_AND_CREDITS
              )
                ? [
                    {
                      path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterDeposits,
                      element: <AdminHubStatementBillingCenterDeposits />,
                      children: [
                        {
                          path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterDepositsByMonth,
                          element: (
                            <AdminHubStatementBillingCenterDepositsByMonth />
                          )
                        },
                        {
                          path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterDepositsByResident,
                          element: (
                            <AdminHubStatementBillingCenterDepositsByResident />
                          )
                        }
                      ]
                    }
                  ]
                : []),

              {
                path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterSettings,
                element: <AdminHubStatementBillingCenterSettings />,
                children: [
                  ...(FeatureFlagService.isEnabled(
                    ExacareFeature.BILLING_GENERAL_SETTINGS
                  )
                    ? [
                        {
                          path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterSettingsGeneral,
                          element: (
                            <AdminHubStatementBillingCenterSettingsGeneral />
                          )
                        }
                      ]
                    : []),

                  {
                    path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterSettingsResidentDeliveryPods,
                    element: (
                      <AdminHubStatementBillingCenterSettingsResidentDeliveryPods />
                    )
                  }
                ]
              },
              {
                path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterOneTimeBills,
                element: <AdminHubStatementBillingCenterOneTimeBills />
              }
            ]
          },
          {
            path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterResidentBill,
            element: <AdminHubStatementBillingCenterResidentBill />
          },
          {
            path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterResidentOneTimeBill,
            element: <AdminHubStatementBillingCenterResidentOneTimeBill />
          },
          {
            path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterManagePaymentMethods,
            element: <AdminHubStatementBillingCenterManagePaymentMethods />
          },
          {
            path: AdminHubStatementBillingCenterRoutes.AdminHubStatementBillingCenterCustomizeGeneralSettings,
            element: <AdminHubStatementBillingCenterCustomizeGeneralSettings />
          }
        ]
      : [])
  ];
};
