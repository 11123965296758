/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Icd10Code } from './Icd10Code';
import {
    Icd10CodeFromJSON,
    Icd10CodeFromJSONTyped,
    Icd10CodeToJSON,
} from './Icd10Code';

/**
 * 
 * @export
 * @interface PccPatientCondition
 */
export interface PccPatientCondition {
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PccPatientCondition
     */
    is_therapy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    clinical_status: PccPatientConditionClinicalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    onset_date: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    resolved_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    rank: PccPatientConditionRankEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    additional_information?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    icd_10_code_id?: string;
    /**
     * 
     * @type {Icd10Code}
     * @memberof PccPatientCondition
     */
    icd_10_code?: Icd10Code;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCondition
     */
    pcc_historical_condition_id?: string;
}


/**
 * @export
 */
export const PccPatientConditionClinicalStatusEnum = {
    Active: 'Active',
    Resolved: 'Resolved'
} as const;
export type PccPatientConditionClinicalStatusEnum = typeof PccPatientConditionClinicalStatusEnum[keyof typeof PccPatientConditionClinicalStatusEnum];

/**
 * @export
 */
export const PccPatientConditionRankEnum = {
    Primary: 'Primary',
    Secondary: 'Secondary'
} as const;
export type PccPatientConditionRankEnum = typeof PccPatientConditionRankEnum[keyof typeof PccPatientConditionRankEnum];


/**
 * Check if a given object implements the PccPatientCondition interface.
 */
export function instanceOfPccPatientCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clinical_status" in value;
    isInstance = isInstance && "onset_date" in value;
    isInstance = isInstance && "rank" in value;

    return isInstance;
}

export function PccPatientConditionFromJSON(json: any): PccPatientCondition {
    return PccPatientConditionFromJSONTyped(json, false);
}

export function PccPatientConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'is_therapy': !exists(json, 'is_therapy') ? undefined : json['is_therapy'],
        'clinical_status': json['clinical_status'],
        'onset_date': json['onset_date'],
        'resolved_date': !exists(json, 'resolved_date') ? undefined : json['resolved_date'],
        'rank': json['rank'],
        'additional_information': !exists(json, 'additional_information') ? undefined : json['additional_information'],
        'icd_10_code_id': !exists(json, 'icd_10_code_id') ? undefined : json['icd_10_code_id'],
        'icd_10_code': !exists(json, 'icd_10_code') ? undefined : Icd10CodeFromJSON(json['icd_10_code']),
        'pcc_historical_condition_id': !exists(json, 'pcc_historical_condition_id') ? undefined : json['pcc_historical_condition_id'],
    };
}

export function PccPatientConditionToJSON(value?: PccPatientCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_therapy': value.is_therapy,
        'clinical_status': value.clinical_status,
        'onset_date': value.onset_date,
        'resolved_date': value.resolved_date,
        'rank': value.rank,
        'additional_information': value.additional_information,
        'icd_10_code_id': value.icd_10_code_id,
        'icd_10_code': Icd10CodeToJSON(value.icd_10_code),
        'pcc_historical_condition_id': value.pcc_historical_condition_id,
    };
}

