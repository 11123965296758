import { RouteObject } from 'react-router-dom';

import { getAddStaffRoutes } from './getAddStaffRoutes';
import { getAdminHubAssessmentRoutes } from './getAdminHubAssessmentRoutes';
import { getAdminHubGeneralRoutes } from './getAdminHubGeneralRoutes';
import { getAdminHubPricesProductRoutes } from './getAdminHubPricesProductRoutes';
import { getAdminHubProviderRoutes } from './getAdminHubProviderRoutes';
import { getAdminHubStaffOverviewRoutes } from './getAdminHubStaffOverviewRoutes';
import { getAdminHubStatementBillingCenterRoutes } from './getAdminHubStatementBillingCenterRoutes';
import { getDataCenterRoutes } from './getDataCenterRoutes';
import { getStaffRoutes } from './getStaffRoutes';
export const getSharedRoutes = (): RouteObject[] => {
  return [
    ...getAddStaffRoutes(),
    ...getAdminHubAssessmentRoutes(),
    ...getAdminHubGeneralRoutes(),
    ...getAdminHubPricesProductRoutes(),
    ...getAdminHubProviderRoutes(),
    ...getAdminHubStatementBillingCenterRoutes(),
    ...getAdminHubStaffOverviewRoutes(),
    ...getDataCenterRoutes(),
    ...getStaffRoutes()
  ];
};

// TODO: Create a generic catch-all route for shared/ehr/crm.
