import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { HTTPError } from 'ky';
dayjs.extend(LocalizedFormat);

import { fetchTimeclock } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import postAccessUrl, { PostAccessUrlPayload } from '@/api/postAccessUrl';
import { postS3Files } from '@/api/postS3Files';
import {
  ShiftBlockSummaryModel,
  ShiftBlockSummaryPayload
} from '@/models/ShiftBlockSummaryModel';
import {
  ShiftBlockPayableTotalsModel,
  ShiftBlockPayableTotalsPayload
} from '@/pages/AdminHub/StaffOverview/StaffPayableTotals/ShiftBlockPayableTotalsModel';
import {
  ShiftBlockModel,
  ShiftBlockPayload
} from '@/pages/AdminHub/StaffOverview/StaffShiftView/ShiftBlockModel';

export type UserClockAction = 'in' | 'out';

export interface UserClockPayload {
  id: string;
  date: string;
  s3_key: string | null;
  is_clock_in: boolean;
  shift_block_id: string;
  timestamp: string;
  updatedAt: string;
  createdAt: string;
  deleted_at: string | null;
}

interface CreateUserClockMutationPayload {
  dataUrl: string | null;
  date: dayjs.Dayjs;
  pin: string;
  isClockIn: boolean;
}

export interface CreateUserClockPayload {
  timestamp: string;
  s3_key: string | null;
  is_clock_in: boolean;
  pin: string;
}

export type UseCreateUserClockMutationResult = UseMutationResult<
  UserClockPayload,
  HTTPError,
  CreateUserClockMutationPayload,
  unknown
>;

interface QueryTimeClockShiftsParams {
  facility_id?: string;
  start_date: string;
  end_date: string;
}

export interface UpdateShiftBlockParams {
  shift_block_id: string;
  payable_time_override: string | null;
  start_time_override: string | null;
  end_time_override: string | null;
  comment: string | null;
  user_clocks: Exclude<UserClockPayload, 'id'>[];
  is_comment_only?: boolean;
}

export interface CreateShiftBlockParams {
  user_id: string;
  payable_time_override: string | null;
  start_time_override: string | null;
  end_time_override: string | null;
  comment: string | null;
  user_clocks: []; // Empty array because individual user clocks not supported
}

interface UserClockStatusPayload {
  date: string | null;
  lastClockedOutTimestamp: string | null;
}

const QUERY_USER_CLOCK_STATUS_KEY = 'queryUserClockStatus';
const QUERY_SHIFT_BLOCK_SUMMARIES_KEY = 'queryShiftBlockSummaries';
const QUERY_SHIFT_BLOCK_KEY = 'queryShiftBlock';
const QUERY_SHIFT_BLOCK_PAYABLE_TOTALS_KEY = 'queryShiftBlockPayableTotals';
const QUERY_USER_SHIFT_BLOCK_SUMMARY_KEY = `queryUserShiftBlockSummary`;

const invalidateClockStatus = () =>
  queryClient.invalidateQueries([QUERY_USER_CLOCK_STATUS_KEY]);
const getQueryKeyWithSearchParams = (
  queryKey:
    | typeof QUERY_SHIFT_BLOCK_SUMMARIES_KEY
    | typeof QUERY_SHIFT_BLOCK_PAYABLE_TOTALS_KEY
    | typeof QUERY_USER_SHIFT_BLOCK_SUMMARY_KEY,
  params: Partial<QueryTimeClockShiftsParams> = {}
) => [queryKey, params.facility_id, params.start_date, params.end_date];
const invalidateShiftBlockSummariesQuery = () =>
  queryClient.invalidateQueries([QUERY_SHIFT_BLOCK_SUMMARIES_KEY]);
const invalidateShiftBlockQuery = () =>
  queryClient.invalidateQueries([QUERY_SHIFT_BLOCK_KEY]);
const invalidateShiftBlockPayableTotalsQuery = () => {
  queryClient.invalidateQueries([QUERY_SHIFT_BLOCK_PAYABLE_TOTALS_KEY]);
};
const invalidateUserShiftBlockSummaryQuery = () => {
  queryClient.invalidateQueries([QUERY_USER_SHIFT_BLOCK_SUMMARY_KEY]);
};

export const useTimeClock = () => {
  return {
    invalidateClockStatus,
    // The invalidation query keys purposefully contain only the query key and
    // no other n-level deep identifiers. This makes it simplier to bust the
    // cache
    invalidateShiftBlockSummariesQuery,
    invalidateShiftBlockQuery,
    invalidateShiftBlockPayableTotalsQuery,
    invalidateUserShiftBlockSummaryQuery,

    queryClockStatus: (options: UseQueryOptions<unknown, HTTPError> = {}) =>
      useQuery(
        [QUERY_USER_CLOCK_STATUS_KEY],
        () => fetchTimeclock.get<UserClockStatusPayload>('/user-clock-status'),
        options as any
      ),

    queryShiftBlockSummaries: (
      searchParams: QueryTimeClockShiftsParams,
      options: UseQueryOptions<ShiftBlockSummaryPayload[], HTTPError> = {}
    ) => {
      return useQuery(
        getQueryKeyWithSearchParams(
          QUERY_SHIFT_BLOCK_SUMMARIES_KEY,
          searchParams
        ),
        async () => {
          const payloads = await fetchTimeclock.get<ShiftBlockSummaryPayload[]>(
            '/shift-blocks/summary',
            {
              searchParams
            }
          );
          return payloads.map((payload) => new ShiftBlockSummaryModel(payload));
        },
        options as any
      );
    },

    queryShiftBlock: (id: string, options: UseQueryOptions = {}) =>
      useQuery(
        [QUERY_SHIFT_BLOCK_KEY, id],
        async () => {
          const payload = await fetchTimeclock.get<ShiftBlockPayload>(
            `/shift-blocks/${id}`
          );
          const photoKeys: string[] = [];
          if (payload.photos.clockIn) {
            photoKeys.push(payload.photos.clockIn);
          }
          if (payload.photos.clockOut) {
            photoKeys.push(payload.photos.clockOut);
          }
          let signedUrls: PostAccessUrlPayload[] = [];
          if (photoKeys.length) {
            try {
              signedUrls = await postAccessUrl(photoKeys);
            } catch (e) {
              console.warn('Error fetching time block photo keys', e);
            }
          }
          return new ShiftBlockModel(payload, signedUrls);
        },
        options as any
      ),

    queryShiftBlockPayableTotals: (
      searchParams: QueryTimeClockShiftsParams,
      options: UseQueryOptions<ShiftBlockPayableTotalsModel[], HTTPError> = {}
    ) => {
      return useQuery(
        getQueryKeyWithSearchParams(
          QUERY_SHIFT_BLOCK_PAYABLE_TOTALS_KEY,
          searchParams
        ),
        async () => {
          const data = await fetchTimeclock.get<
            ShiftBlockPayableTotalsPayload[]
          >('/shift-blocks/payable-totals', {
            searchParams
          });
          return data.map(
            (payload) => new ShiftBlockPayableTotalsModel(payload)
          );
        },
        options as any
      );
    },

    queryUserShiftBlockSummary: (
      searchParams: QueryTimeClockShiftsParams,
      options: UseQueryOptions<ShiftBlockSummaryPayload[], HTTPError> = {}
    ) => {
      return useQuery(
        getQueryKeyWithSearchParams(
          QUERY_USER_SHIFT_BLOCK_SUMMARY_KEY,
          searchParams
        ),
        async () => {
          const payloads = await fetchTimeclock.get<ShiftBlockSummaryPayload[]>(
            '/users/shift-blocks/summary',
            {
              searchParams
            }
          );
          return payloads.map((payload) => new ShiftBlockSummaryModel(payload));
        },
        options as any
      );
    },

    useUpdateShiftBlockMutation: (
      options: UseMutationOptions<
        ShiftBlockModel,
        HTTPError,
        Partial<UpdateShiftBlockParams>
      > = {}
    ) =>
      useMutation(async (params: Partial<UpdateShiftBlockParams>) => {
        const paramsWithoutId = { ...params };
        delete paramsWithoutId.shift_block_id;
        const payload = await fetchTimeclock.put<ShiftBlockPayload>(
          `/shift-blocks/${params.shift_block_id}`,
          params
        );
        return new ShiftBlockModel(payload);
      }, options),

    useCreateShiftBlockMutation: (
      options: UseMutationOptions<
        ShiftBlockPayload,
        HTTPError,
        Partial<CreateShiftBlockParams>
      > = {}
    ) =>
      useMutation(async (params: Partial<CreateShiftBlockParams>) => {
        const payload = await fetchTimeclock.post<ShiftBlockPayload>(
          `/shift-blocks`,
          params
        );
        return new ShiftBlockModel(payload);
      }, options),

    useCreateUserClockMutation: (
      options: UseMutationOptions<
        CreateUserClockMutationPayload,
        HTTPError
      > = {}
    ): UseCreateUserClockMutationResult =>
      useMutation(async (params: CreateUserClockMutationPayload) => {
        const blob = await (await fetch(params.dataUrl as string)).blob();
        const file = new File([blob], 'clock-in.png', {
          type: 'image/png',
          lastModified: new Date().getTime()
        });
        let s3Key: string | null = null;

        if (params.dataUrl) {
          try {
            const [{ key }] = await postS3Files([file]);
            s3Key = key;
          } catch (e) {
            console.error('Error uploading s3 file', e);
          }
        }

        const payload: CreateUserClockPayload = {
          pin: params.pin,
          s3_key: s3Key,
          is_clock_in: params.isClockIn,
          timestamp: params.date.toISOString()
        };

        return fetchTimeclock.post<UserClockPayload>('/user-clock', payload);
      }, options as any)
  };
};
