import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ProtectedRoute } from '../ProtectedRoute';
import { RoutePath } from '../RoutePath';

const AdminHubAssessments = loadable(() => import("@/pages/AdminHub/Assessments")); // prettier-ignore
const AdminHubAssessmentsCareLevels = loadable(() => import("@/pages/AdminHub/Assessments/CareLevels")); // prettier-ignore
const AdminHubAssessmentsLibrary = loadable(() => import("@/pages/AdminHub/Assessments/Library")); // prettier-ignore
const AdminHubAssessmentsNeedGoalRepository = loadable(() => import("@/pages/AdminHub/Assessments/NeedGoalRepository/NeedGoalRepository")); // prettier-ignore
const AdminHubAssessmentsReviewList = loadable(() => import('@/pages/AdminHub/Assessments/ReviewList')); // prettier-ignore
const AdminHubResidentAssessmentReview = loadable(() => import("@/pages/AdminHub/Assessments/Review")); // prettier-ignore
const AdminHubResidentAssessmentReviewAnswers = loadable(() => import("@/pages/AdminHub/Assessments/Review/Answers/Answers")); // prettier-ignore
const AdminHubResidentAssessmentReviewCarePlan = loadable(() => import("@/pages/AdminHub/Assessments/Review/CarePlan/CarePlanReview")); // prettier-ignore
const AdminHubResidentAssessmentReviewCareSubscription = loadable(() => import("@/pages/AdminHub/Assessments/Review/CareSubscription/CareSubscription")); // prettier-ignore
const AdminHubResidentAssessmentReviewSignature = loadable(() => import("@/pages/AdminHub/Assessments/Review/Signature/Signature")); // prettier-ignore
const AdminHubResidentAssessmentReviewSummary = loadable(() => import("@/pages/AdminHub/Assessments/Review/Summary/CarePlanSummary")); // prettier-ignore

export enum AdminHubAssessmentRoutes {
  AdminHubAssessment = '/:domain/admin-hub/assessment/:assessment_id',
  AdminHubAssessments = '/:domain/admin-hub/assessments',
  AdminHubAssessmentsLibrary = '/:domain/admin-hub/assessments/library',
  AdminHubAssessmentsReview = '/:domain/admin-hub/assessments/review',
  AdminHubAssessmentsCareLevels = '/:domain/admin-hub/assessments/care-levels',
  AdminHubAssessmentsNeedGoalRepository = '/:domain/admin-hub/assessments/need-goal-repository',
  AdminHubResidentAssessmentCompletions = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id',
  AdminHubResidentAssessmentCompletionsCarePlanReview = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id/care-plan',
  AdminHubResidentAssessmentCompletionsCareSubscription = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id/care-subscription',
  AdminHubResidentAssessmentCompletionsCarePlanSummary = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id/care-plan-summary',
  AdminHubResidentAssessmentCompletionsAnswers = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id/assessment-answers',
  AdminHubResidentAssessmentCompletionsSignature = '/:domain/admin-hub/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completions/:completion_id/e-signature'
}

export const getAdminHubAssessmentRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    ...(currentUser.isAssessmentsEnabled()
      ? [
          {
            path: RoutePath.AdminHubAssessments,
            element: <AdminHubAssessments />,
            children: [
              {
                path: RoutePath.AdminHubAssessmentsLibrary,
                element: <AdminHubAssessmentsLibrary />
              },
              {
                path: RoutePath.AdminHubAssessmentsReview,
                element: <AdminHubAssessmentsReviewList />
              },
              {
                path: RoutePath.AdminHubAssessmentsCareLevels,
                element: <AdminHubAssessmentsCareLevels />
              },
              {
                path: RoutePath.AdminHubAssessmentsNeedGoalRepository,
                element: <AdminHubAssessmentsNeedGoalRepository />
              }
            ]
          }
        ]
      : []),
    {
      path: RoutePath.AdminHubResidentAssessmentCompletions,
      element: (
        <ProtectedRoute
          component={AdminHubResidentAssessmentReview}
          role="L1"
        />
      ),
      children: [
        {
          path: RoutePath.AdminHubResidentAssessmentCompletionsCarePlanReview,
          element: <AdminHubResidentAssessmentReviewCarePlan />
        },
        {
          path: RoutePath.AdminHubResidentAssessmentCompletionsCareSubscription,
          element: <AdminHubResidentAssessmentReviewCareSubscription />
        },
        {
          path: RoutePath.AdminHubResidentAssessmentCompletionsCarePlanSummary,
          element: <AdminHubResidentAssessmentReviewSummary />
        },
        {
          path: RoutePath.AdminHubResidentAssessmentCompletionsAnswers,
          element: <AdminHubResidentAssessmentReviewAnswers />
        }
      ]
    },
    // Note: This is purposefully not a child of AdminHubResidentAssessmentCompletions
    ...(currentUser.isAssessmentsEnabled() &&
    FeatureFlagService.isEnabled(ExacareFeature.ASSESSMENTS_CARE_PLAN_ESIGN)
      ? [
          {
            path: RoutePath.AdminHubResidentAssessmentCompletionsSignature,
            element: <AdminHubResidentAssessmentReviewSignature />
          }
        ]
      : [])
  ];
};
