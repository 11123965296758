import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const DataCenterDashboards = loadable(() => import("@/pages/DataCenter/DashboardTab")); // prettier-ignore
const DataCenterReportBuilder = loadable(() => import("@/pages/DataCenter/ReportBuilderTab")); // prettier-ignore
const DataCenterViewDashboard = loadable(() => import("@/pages/DataCenter/ViewDashboard")); // prettier-ignore

export enum DataCenterRoutes {
  DataCenterDashboards = '/:domain/data-center/dashboards',
  DataCenterReportBuilder = '/:domain/data-center/report-builder',
  DataCenterViewDashboard = '/:domain/data-center/dashboards/:explo_dashboard_id'
}

export const getDataCenterRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  if (
    !FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) ||
    !currentUser.isDataCenterEnabled()
  ) {
    return [];
  }

  return [
    {
      path: DataCenterRoutes.DataCenterDashboards,
      element: <DataCenterDashboards />
    },
    {
      path: DataCenterRoutes.DataCenterViewDashboard,
      element: <DataCenterViewDashboard />
    },
    {
      path: DataCenterRoutes.DataCenterReportBuilder,
      element: <DataCenterReportBuilder />
    }
  ];
};
